var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"categories-tree"},[_c('OverlayComponent',{attrs:{"isLoading":_vm.isLoading}}),(_vm.categories.hasOwnProperty('id'))?_c('vue-tree-list',{ref:"node",attrs:{"model":_vm.categories,"default-tree-node-name":"new Category","default-leaf-node-name":"new Sub Category","default-expanded":false},scopedSlots:_vm._u([{key:"leafNameDisplay",fn:function(slotProps){return [(!slotProps.model.addCategory)?_c('span',{staticClass:"text"},[_c('span',{staticClass:"node-text"},[_vm._v(" "+_vm._s(slotProps.model.name)+" ")]),(
            _vm.globalPermission.categories && _vm.globalPermission.categories.update
          )?_c('span',{staticClass:"icon",attrs:{"slot":"editNodeIcon"},on:{"click":function($event){return _vm.editNode(slotProps.model)}},slot:"editNodeIcon"},[_c('img',{attrs:{"src":_vm.penIcon,"alt":"pen-icon"}})]):_vm._e(),(
            _vm.globalPermission.categories && _vm.globalPermission.categories.destroy
          )?_c('span',{staticClass:"icon",attrs:{"slot":"delNodeIcon"},on:{"click":function($event){return _vm.deleteCategory(slotProps.model.slug)}},slot:"delNodeIcon"},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1):_vm._e()]):(
          slotProps.model.addCategory &&
          _vm.globalPermission.categories &&
          _vm.globalPermission.categories.store
        )?_c('span',{staticClass:"add-cat",on:{"click":function($event){return _vm.onAddNode(slotProps.model.pid)}}},[_vm._v(" "+_vm._s(slotProps.model.pid > 0 ? _vm.$t("g.addSubCategory") : _vm.$t("g.addCategory"))+" ")]):_vm._e()]}},{key:"treeNodeIcon",fn:function(slotProps){return [(!slotProps.model.addCategory)?_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.notActiveFolder,"alt":"folder-img"}})]):(
          _vm.globalPermission.categories && _vm.globalPermission.categories.store
        )?_c('span',{staticClass:"plus-icon",on:{"click":function($event){return _vm.onAddNode(slotProps.model.pid)}}},[_c('img',{staticClass:"ico",attrs:{"src":_vm.plusIcon,"alt":"folder-img"}})]):_vm._e()]}}],null,false,524024746)},[_c('span',{staticClass:"icon",attrs:{"slot":"leafNodeIcon"},slot:"leafNodeIcon"},[_c('img',{attrs:{"src":_vm.notActiveFile,"alt":"folder-img"}})])]):_vm._e(),_c('add-category',{attrs:{"pid":_vm.pidToAdd},on:{"resetData":_vm.getCategories}}),_c('edit-category',{attrs:{"slug":_vm.slugToEdit},on:{"resetData":_vm.getCategories}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }