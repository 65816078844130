<template>
  <categories-tree />
</template>

<script>
import categoriesTree from "@/components/categoriesTree";
import { BCard } from "bootstrap-vue";

export default {
  name: "CategoriesList",
  components: {
    BCard,
    categoriesTree,
  },
};
</script>

<style src="./_index.scss" lang="scss" scoped />
