<template>
  <div class="stage-component">
    <span v-for="(stage, index) in stages" :key="index" class="continer">
      <button
        role="button"
        :class="`bill ${stage.active ? 'active' : ''} ${
          stage.disabled ? 'disabled' : ''
        }`"
        @click="!stage.disabled ? changeStage(stage.stage) : null"
      >
        <span class="stage-bill">{{ stage.stage }}</span>
        <h5>{{ stage.text }}</h5>
      </button>
      <div class="separete" v-if="stages.length != stage.stage">
        <div
          :class="`line ${
            index != 0 || (stages[index + 1] && stages[index + 1].active)
              ? 'active'
              : ' '
          }`"
        ></div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: "StageComponent",
  props: ["stages"],
  methods: {
    changeStage(stage) {
      this.$emit("changeStage", stage);
    },
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
