<template>
  <div class="editCategoryModel">
    <b-modal
      id="edit-modal-category"
      hide-footer
      centered
      :title="$t('edit-modal-category')"
    >
      <stage-component :stages="stages" @changeStage="changeStage" />

      <validation-observer ref="editCategoryForm" v-if="currentStage == 1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('g.addCateogry/form/category_name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  class="input-style"
                  v-model="form_data.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="image-input-area">
              <b-form-file
                plain
                hidden="hidden"
                class="image-input"
                ref="header"
                v-model="form_data.image"
                @change="handelImageChange"
                accept="image/*"
              />
              <div class="image-icon" @click.prevent="$refs.header.$el.click()">
                <img
                  v-if="!form_data.image || form_data.image.length === 0"
                  class="img"
                  :src="importIco"
                  alt="Cover"
                />
                <img
                  class="img"
                  v-else
                  style="height: 100px; width: 100px"
                  :src="imageUrl || form_data.image.path"
                  alt="Cover"
                />
              </div>
              <p
                @click.prevent="$refs.header.$el.click()"
                v-if="!form_data.image || form_data.image.length == 0"
                class="image-text mb-0"
              >
                {{ $t("g.upload_photo") }}
              </p>
            </div>
            <RecommendationUploadImage
              :dimensions="{ width: 1200, height: 750 }"
            />
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('g.addCateogry/form/caregory_priority')"
              label-for="catrgory"
            >
              <validation-provider
                #default="{ errors }"
                name="catrgory"
                rules="required"
              >
                <b-form-select
                  v-model="form_data.priority"
                  class="input-style"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option :value="null" disabled
                    >{{ $t("g.categories/models/add/select_priorty") }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="item in priorities"
                    :value="item.value"
                    :key="item.value"
                  >
                    {{ item.text }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="$t('g.addCateogry/form/visiablity')"
              label-for="visiablity"
            >
              <validation-provider
                #default="{ errors }"
                name="visiablity"
                rules="required"
              >
                <b-form-checkbox
                  class="check-box"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.is_visible"
                  switch
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center my-1">
          <b-button class="col col-10" variant="primary" @click="submit">
            {{ $t("g.addCategory/save") }}
          </b-button>
        </b-row>
      </validation-observer>
      <EditSpecKeysComponent
        v-else
        :category_id="form_data.id"
        @toggle-loading="isloading = !isloading"
        @rest-Page="resetPage"
      />
      <overlay-component :isLoading="isloading" />
    </b-modal>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BIconNodeMinusFill,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
import EditSpecKeysComponent from "./EditSpecKeysComponent";
import StageComponent from "@/components/categoriesTree/models/StageComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  props: ["slug"],
  components: {
    BRow,
    BCol,
    BFormFile,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    BIconNodeMinusFill,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    OverlayComponent,
    BModal,
    StageComponent,
    EditSpecKeysComponent,
    RecommendationUploadImage
  },
  data() {
    return {
      form_data: {
        name: null,
        priority: null,
        is_visible: true,
        image: null,
        parent_id: null,
        id: null,
        is_leaf: true,
      },
      archive: {},
      imageUrl: null,
      isloading: true,
      imgIco: require("@/assets/images/pages/imgIco.png"),
      importImge: require("@/assets/images/pages/importImg.png"),
      importIco: require("@/assets/images/pages/plus.svg"),
      PenIco: require("@/assets/images/pages/pencil.svg"),
      priorities: [
        {
          text: this.$t("g.addCtegory/form/priority/veryhigh"),
          value: "5",
        },
        {
          text: this.$t("g.addCtegory/form/priority/high"),
          value: "4",
        },
        {
          text: this.$t("g.addCtegory/form/priority/avrage"),
          value: "3",
        },
        {
          text: this.$t("g.addCtegory/form/priority/Low"),
          value: "2",
        },
        {
          text: this.$t("g.addCtegory/form/priority/veryLow"),
          value: "1",
        },
      ],
      stages: [
        {
          stage: 1,
          text: this.$t("g.Category/stage/mianData"),
          active: true,
          disabled: false,
        },
        {
          stage: 2,
          text: this.$t("g.Category/stage/specs"),
          active: false,
          disabled: false,
        },
      ],
      currentStage: 1,
    };
  },

  methods: {
    async getData() {
      try {
        this.currentStage = 1;
        this.isloading = true;
        const data = await this.$http.get(`admin/categories/${this.slug}`);
        for (const item in this.form_data) {
          this.form_data[item] = data.data.data[item];
          this.archive[item] = data.data.data[item];
          if (item === "image") {
            this.archive["image"] = data.data.data["media"];
            this.form_data["image"] = data.data.data["media"];
          }
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isloading = false;
      }
    },
    async submit() {
      this.$refs.editCategoryForm.validate().then(async (sucess) => {
        if (sucess) {
          try {
            const formData = new FormData();
            for (const item in this.form_data) {
              if (this.form_data[item] !== null) {
                formData.append(item, this.form_data[item]);
                if (item === "is_visible") {
                  formData.append(item, this.form_data[item] ? 1 : 0);
                }
              }
            }

            formData.append("lang", this.$i18n.locale);

            if (this.form_data.name === this.archive.name) {
              formData.delete("name");
            }
            if (this.form_data.image.name === this.archive.image?.name) {
              formData.delete("image");
            }
            const response = await this.$http.post(
              `admin/categories/${this.slug}?_method=put`,
              formData
            );
            if (response.status == 200 || 201) {
              this.currentStage = 2;
            }
          } catch (err) {
            this.$helpers.handleError(err);
          } finally {
            this.isloading = false;
          }
        }
      });
    },
    deleteAll() {
      this.form_data.image = null;
    },
    resetData() {
      for (const item in this.form_data) {
        if (item != "id") {
          this.form_data[item] = null;
        }
      }
    },
    changeStage(newStage) {
      this.currentStage = newStage;
    },
    resetPage() {
      this.resetData();
      this.$bvModal.hide("edit-modal-category");
      this.$emit("resetData");
    },
    handelImageChange(event) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    },
  },
  watch: {
    slug: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.resetData();
          this.getData();
        }
      },
    },
    currentStage(newVal, oldVal) {
      const newValue = this.stages.map((ele) => {
        if (ele.stage && ele["stage"] == newVal) {
          ele.active = true;
          return { ...ele };
        } else {
          ele.active = false;
          return { ...ele };
        }
      });
      this.stages = newValue;
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
