var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addCategoryModel"},[_c('b-modal',{attrs:{"id":"add-modal-category","hide-footer":"","title":_vm.$t('g.add-modal-category'),"centered":""}},[_c('stage-component',{attrs:{"stages":_vm.stages},on:{"changeStage":_vm.changeStage}}),(_vm.currentStage == 1)?_c('validation-observer',{ref:"addCategoryForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.addCateogry/form/category_name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,1554906469)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"header",staticClass:"image-input",attrs:{"plain":"","hidden":"hidden","accept":"image/*"},on:{"change":_vm.handelImageChange},model:{value:(_vm.form_data.image),callback:function ($$v) {_vm.$set(_vm.form_data, "image", $$v)},expression:"form_data.image"}}),_c('div',{staticClass:"image-icon",on:{"click":function($event){$event.preventDefault();return _vm.$refs.header.$el.click()}}},[(!_vm.form_data.image || _vm.form_data.image.length == 0)?_c('img',{staticClass:"img",attrs:{"src":_vm.importIco,"alt":"Cover"}}):_c('img',{staticClass:"img",attrs:{"src":_vm.imageUrl,"alt":"Cover"}})]),(!_vm.form_data.image || _vm.form_data.image.length == 0)?_c('p',{staticClass:"image-text mb-0",on:{"click":function($event){$event.preventDefault();return _vm.$refs.header.$el.click()}}},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),(!_vm.form_data.image || _vm.form_data.image.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3098651185)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.addCateogry/form/caregory_priority'),"label-for":"catrgory"}},[_c('validation-provider',{attrs:{"name":"catrgory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.priority),callback:function ($$v) {_vm.$set(_vm.form_data, "priority", $$v)},expression:"form_data.priority"}},[_c('b-form-select-option',{attrs:{"value":0,"disabled":""}},[_vm._v(_vm._s(_vm.$t("g.categories/models/add/select_priorty"))+" ")]),_vm._l((_vm.priorities),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2349273357)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.addCateogry/form/visiablity'),"label-for":"visiablity"}},[_c('validation-provider',{attrs:{"name":"visiablity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-box",attrs:{"state":errors.length > 0 ? false : null,"switch":""},model:{value:(_vm.form_data.is_visible),callback:function ($$v) {_vm.$set(_vm.form_data, "is_visible", $$v)},expression:"form_data.is_visible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,447993658)})],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-center my-1"},[_c('b-button',{staticClass:"col col-10",attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.myId ? _vm.$t("g.addCategory/next") : _vm.$t("g.addCategory/next"))+" ")])],1)],1):_vm._e(),(_vm.currentStage == 2)?_c('AddSpecKeysComponent',{attrs:{"form_data":_vm.form_data,"id":_vm.myId,"myPid":_vm.myPid},on:{"toggle-loading":function($event){_vm.isloading = false},"reset-page-data":_vm.resetPage}}):_vm._e(),_c('overlay-component',{attrs:{"isLoading":_vm.isloading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }