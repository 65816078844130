<template>
  <div class="edit-specs">
    <b-table :fields="fields" class="table" :items="keys">
      <template #head(key)>
        {{ $t("categoryKEy") }}
      </template>
      <template #cell(key)="dataKEy">
        <input
          :ref="dataKEy.item.id"
          :contenteditable="
            globalPermission['spec-keys'] &&
            globalPermission['spec-keys'].update
          "
          :disabled="
            globalPermission['spec-keys'] &&
            !globalPermission['spec-keys'].update
          "
          class="unset-all"
          @input="inputHandelar(dataKEy.item)"
          v-model="dataKEy.item.value"
        />
      </template>
    </b-table>
    <button
      v-if="
        globalPermission['spec-keys'] && globalPermission['spec-keys'].store
      "
      class="add-key-btn"
      @click="addNewKey"
    >
      <img :src="plusIcon" alt="plus-icon" />
      {{ $t("g.addCtategory/form/addKey") }}
    </button>
    <b-row class="d-flex justify-content-center m-1">
      <b-button variant="primary" class="col col-8" @click="submitKeys">
        {{ $t("g.addCategory/save") }}
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { BTable, BButton, BRow, BFormCheckbox } from "bootstrap-vue";

export default {
  props: ["category_id"],
  components: {
    BTable,
    BButton,
    BRow,
    BFormCheckbox,
  },

  data() {
    return {
      keys: [],
      fields: ["key"],
      plusIcon: require("@/assets/images/icons/plus-sm.svg"),
    };
  },
  created() {
    this.getSpecKeys();
  },
  methods: {
    inputHandelar({ id }) {
      const chenged = this.keys.find((e) => e.id == id);
      this.keys = this.keys.map((ele) => {
        if (ele.id === chenged.id) {
          ele.changed = true;
          return ele;
        } else {
          return ele;
        }
      });
    },
    addNewKey() {
      this.keys.push({
        id: +this.keys.length + 1,
        value: null,
        is_required: true,
        changed: true,
        new: true,
      });
    },
    async submitKeys() {
      try {
        const newKeys = this.prepareAddKeys();
        if (newKeys && newKeys.length > 0) {
          const newKeyFormData = new FormData();
          newKeys.forEach((ele, index) => {
            for (const item in ele) {
              const notWantedKeys = [
                "value",
                "new",
                "is_required",
                "changed",
                "id",
              ];
              if (!notWantedKeys.includes(item)) {
                if (item === "name") {
                  newKeyFormData.append(
                    `spec_keys[${index}][${item}]`,
                    ele[item]
                  );
                  newKeyFormData.append(`spec_keys[${index}][key]`, ele[item]);
                } else {
                  newKeyFormData.append(
                    `spec_keys[${index}][${item}]`,
                    ele[item]
                  );
                }
              }
              if (item === "is_required") {
                newKeyFormData.append(
                  `spec_keys[${index}][${item}]`,
                  Boolean(ele[item]) ? 1 : 0
                );
              }
            }
          });
          newKeyFormData.append("category_id", this.category_id);
          await this.$http.post("admin/spec-keys", newKeyFormData);
        }
        // * add new keys
        const formData = new FormData();
        const editesKeys = this.prepareEditArray();
        if (editesKeys.length > 0) {
          editesKeys.forEach((ele, index) => {
            for (const item in ele) {
              const notWantedKeys = [
                "value",
                "changed",
                "new",
                "id",
                "is_required",
              ];

              if (!notWantedKeys.includes(item)) {
                if (item === "name") {
                  formData.append(`spec_keys[${index}][${item}]`, ele[item]);
                  formData.append(`spec_keys[${index}][key]`, ele[item]);
                } else {
                  formData.append(`spec_keys[${index}][${item}]`, ele[item]);
                }
              }
              if (item == "is_required") {
                formData.append(
                  `spec_keys[${index}][is_required]`,
                  Boolean(ele["is_required"]) ? 1 : 0
                );
              }
            }
          });
          formData.append("category_id", this.category_id);
          const response = await this.$http.post(
            `admin/spec-keys/${this.category_id}?_method=put`,
            formData
          );
          if (response.status === 200 || response.status === 201) {
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
            this.$bvModal.hide("edit-modal-category");
          }
        } else {
          this.$emit("rest-Page");
          this.$bvModal.hide("edit-modal-category");
        }
      } catch (err) {
        this.$helpers.handleError(err);
      }
    },

    async getSpecKeys() {
      if (this.category_id) {
        this.$emit("toggle-loading");
        try {
          const response = await this.$http.get(
            `admin/spec-keys?category_id=${this.category_id}`
          );

          this.keys.length = 0;
          response.data?.data?.forEach(({ id, key, is_required, name }) => {
            this.keys.push({
              id,
              value: name,
              is_required,
              new: false,
              changed: false,
            });
          });
          this.$emit("toggle-loading");
        } catch (err) {
          this.$helpers.handleError(err);
        }
      }
    },
    prepareAddKeys() {
      let newkeys = [];
      for (let item of this.keys) {
        if (item?.new) newkeys.push(item);
      }
      return newkeys.map((ele) => {
        ele.key = ele.value;
        ele.name = ele.value;
        ele.lang = this.$i18n.locale;
        return ele;
      });
    },
    prepareEditArray() {
      const editedkeys = [];
      this.keys.forEach((item) => {
        if (item.changed && !item.new) {
          editedkeys.push(item);
        }
      });
      for (let item of editedkeys) {
        item["spec_key_id"] = item.id;
        item["name"] = item.value;
        item["key"] = item.value;
        item["lang"] = this.$i18n.locale;
      }
      return editedkeys;
    },
    handelChangeRequierd(id, is_required) {
      const changedkey = this.keys.find((e) => e?.id == id);
      changedkey.changed = true;
    },
  },
  watch: {
    category_id(newVal, oldVal) {
      this.getSpecKeys();
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
