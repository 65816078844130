<template>
  <b-card class="categories-tree">
    <OverlayComponent :isLoading="isLoading" />
    <vue-tree-list
      v-if="categories.hasOwnProperty('id')"
      ref="node"
      :model="categories"
      default-tree-node-name="new Category"
      default-leaf-node-name="new Sub Category"
      v-bind:default-expanded="false"
    >
      <template v-slot:leafNameDisplay="slotProps">
        <span class="text" v-if="!slotProps.model.addCategory">
          <span class="node-text">
            {{ slotProps.model.name }}
          </span>

          <span
            v-if="
              globalPermission.categories && globalPermission.categories.update
            "
            class="icon"
            slot="editNodeIcon"
            @click="editNode(slotProps.model)"
          >
            <img :src="penIcon" alt="pen-icon"
          /></span>
          <span
            v-if="
              globalPermission.categories && globalPermission.categories.destroy
            "
            class="icon"
            slot="delNodeIcon"
            @click="deleteCategory(slotProps.model.slug)"
          >
            <feather-icon icon="Trash2Icon" />
          </span>
        </span>
        <span
          v-else-if="
            slotProps.model.addCategory &&
            globalPermission.categories &&
            globalPermission.categories.store
          "
          class="add-cat"
          @click="onAddNode(slotProps.model.pid)"
        >
          {{
            slotProps.model.pid > 0
              ? $t("g.addSubCategory")
              : $t("g.addCategory")
          }}
        </span>
      </template>
      <span class="icon" slot="leafNodeIcon">
        <img :src="notActiveFile" alt="folder-img" />
      </span>
      <template v-slot:treeNodeIcon="slotProps">
        <span class="icon" v-if="!slotProps.model.addCategory">
          <img :src="notActiveFolder" alt="folder-img" />
        </span>
        <span
          class="plus-icon"
          @click="onAddNode(slotProps.model.pid)"
          v-else-if="
            globalPermission.categories && globalPermission.categories.store
          "
        >
          <img :src="plusIcon" alt="folder-img" class="ico" />
        </span>
      </template>
    </vue-tree-list>
    <add-category :pid="pidToAdd" @resetData="getCategories" />
    <edit-category :slug="slugToEdit" @resetData="getCategories" />
  </b-card>
</template>
<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import addCategory from "@/components/categoriesTree/models/addCategory";
import editCategory from "@/components/categoriesTree/models/editCategory";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list";
import { BCard } from "bootstrap-vue";
export default {
  components: {
    VueTreeList,
    addCategory,
    editCategory,
    OverlayComponent,
    BCard,
  },
  data() {
    return {
      categories: {},
      pidToAdd: null,
      slugToEdit: null,
      fileIcon: require("@/assets/images/icons/folder-3-fill.svg"),
      folderIcon: require("@/assets/images/icons/file-2-fill.svg"),
      penIcon: require("@/assets/images/icons/pencil.svg"),
      plusIcon: require("@/assets/images/icons/plus-sm.svg"),
      notActiveFolder: require("@/assets/images/icons/folder-3-line.svg"),
      notActiveFile: require("@/assets/images/icons/file-2-line.svg"),
      isLoading: false,
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        this.isLoading = true;
        const data = await this.$http.get("admin/categories");
        this.categories = data.data.data;

        this.handelTreeNode();
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handelTreeNode() {
      let newData = [];
      this.categories.map((ele) => {
        if (ele.level === 1) {
          newData.push({
            id: ele.id,
            slug: ele.slug,
            name: ele.name,
            isLeaf: ele.is_have_products,
            priority: ele.priority,
            main_color: ele.main_color,
            is_visible: ele.is_visible,
            pId: ele.parentId ? ele.parentId : ele.id,
            delNodeDisabled: true,
            editNodeDisabled: true,
            isExtended: true,
            addLeafNodeDisabled: true,
            addTreeNodeDisabled: true,
            dragDisabled: true,
            level: ele.level,
            children: this.getNodeChildren(
              ele.level,
              ele.id,
              ele.is_have_products
            ),
          });
        }
      });

      this.categories = new Tree(newData[0].children);
      setTimeout(() => {
        this.handelSetOnClink();
      }, 100);
    },
    getNodeChildren(currentLevel, parentId, haveProducts) {
      let children = [];
      if (
        !haveProducts &&
        this.globalPermission.categories &&
        this.globalPermission.categories.store
      ) {
        children.push({
          name: "add-cte",
          addCategory: true,
          pId: parentId,
          delNodeDisabled: true,
          editNodeDisabled: true,
          isExtended: true,
          addLeafNodeDisabled: true,
          addTreeNodeDisabled: true,
          dragDisabled: true,
        });
      }
      this.categories.map((ele) => {
        if (ele.level === currentLevel + 1 && ele.parent_id === parentId) {
          children.push({
            id: ele.id,
            slug: ele.slug,
            name: ele.name,
            isLeaf: ele.is_have_products,
            priority: ele.priority,
            main_color: ele.main_color,
            is_visible: ele.is_visible,
            delNodeDisabled: true,
            editNodeDisabled: true,
            addLeafNodeDisabled: true,
            isExtended: false,
            addTreeNodeDisabled: true,
            dragDisabled: true,
            pid: ele.parent_id,
            level: ele.level,
            children: this.getNodeChildren(
              ele.level,
              ele.id,
              ele.is_have_products
            ),
          });
        }
      });
      return children.filter((ele) => (ele.pId = parentId));
    },

    handelSetOnClink() {
      const caret = document.querySelectorAll(".vtl-caret .vtl-icon");
      caret.forEach((ele) =>
        ele.addEventListener("click", () => this.handleToggleIcon())
      );
    },
    handleToggleIcon() {
      const treeMargins =
        document.querySelectorAll(".vtl .vtl-tree-margin") ?? [];
      treeMargins.forEach((margin) => {
        const parent = margin.parentElement;
        const element =
          parent.childNodes[0].childNodes[1].childNodes[1]?.lastChild
            ?.lastChild;

        if (margin.style.display !== "none") {
          element.src = this.fileIcon;
        } else {
          element.src = this.notActiveFolder;
        }
      });
    },
    onAddNode(parent_id) {
      this.$bvModal.show("add-modal-category");
      this.pidToAdd = parent_id;
    },
    async deleteCategory(categorySlug) {
      try {
        const request = await this.$http.delete(
          `admin/categories/${categorySlug}`
        );
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    editNode(item) {
      this.slugToEdit = item.slug;
      setTimeout(() => {
        this.$bvModal.show("edit-modal-category");
      }, 5);
    },

    toggleExtended(node) {
      node.isExtended = !node.isExtended;
    },
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
