<template>
  <div class="addCategoryModel">
    <b-modal
      id="add-modal-category"
      hide-footer
      :title="$t('g.add-modal-category')"
      centered
    >
      <stage-component :stages="stages" @changeStage="changeStage" />
      <validation-observer ref="addCategoryForm" v-if="currentStage == 1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('g.addCateogry/form/category_name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  class="input-style"
                  v-model="form_data.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <div class="image-input-area">
                <b-form-file
                  plain
                  hidden="hidden"
                  class="image-input"
                  ref="header"
                  v-model="form_data.image"
                  @change="handelImageChange"
                  accept="image/*"
                />
                <div
                  class="image-icon"
                  @click.prevent="$refs.header.$el.click()"
                >
                  <img
                    v-if="!form_data.image || form_data.image.length == 0"
                    :src="importIco"
                    class="img"
                    alt="Cover"
                  />
                  <img class="img" v-else :src="imageUrl" alt="Cover" />
                </div>
                <p
                  @click.prevent="$refs.header.$el.click()"
                  v-if="!form_data.image || form_data.image.length == 0"
                  class="image-text mb-0"
                >
                  {{ $t("g.upload_photo") }}
                </p>
              </div>
              <RecommendationUploadImage
                v-if="!form_data.image || form_data.image.length == 0"
                :dimensions="{ width: 1200, height: 750 }"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('g.addCateogry/form/caregory_priority')"
              label-for="catrgory"
            >
              <validation-provider
                #default="{ errors }"
                name="catrgory"
                rules="required"
              >
                <b-form-select
                  v-model="form_data.priority"
                  class="input-style"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option :value="0" disabled
                    >{{ $t("g.categories/models/add/select_priorty") }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="item in priorities"
                    :value="item.value"
                    :key="item.value"
                  >
                    {{ item.text }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="$t('g.addCateogry/form/visiablity')"
              label-for="visiablity"
            >
              <validation-provider
                #default="{ errors }"
                name="visiablity"
                rules="required"
              >
                <b-form-checkbox
                  class="check-box"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.is_visible"
                  switch
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center my-1">
          <b-button class="col col-10" variant="primary" @click="submit">
            {{ myId ? $t("g.addCategory/next") : $t("g.addCategory/next") }}
          </b-button>
        </b-row>
      </validation-observer>
      <AddSpecKeysComponent
        :form_data="form_data"
        @toggle-loading="isloading = false"
        :id="myId"
        :myPid="myPid"
        @reset-page-data="resetPage"
        v-if="currentStage == 2"
      />
      <overlay-component :isLoading="isloading" />
    </b-modal>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BIconNodeMinusFill,
  BFormCheckbox,
  BModal,
  BTable,
} from "bootstrap-vue";
import AddSpecKeysComponent from "./AddSpecKeysComponent";
import StageComponent from "@/components/categoriesTree/models/StageComponent";
import AddModal from "@/components/shared/AddModal";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BButton,
    AddModal,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    BIconNodeMinusFill,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    OverlayComponent,
    StageComponent,
    BModal,
    BTable,
    AddSpecKeysComponent,
    RecommendationUploadImage,
  },
  props: ["pid"],
  data() {
    return {
      form_data: {
        name: null,
        priority: 0,
        is_visible: true,
        image: null,
      },
      imageUrl: null,
      isloading: false,
      imgIco: require("@/assets/images/pages/imgIco.png"),
      importImge: require("@/assets/images/pages/importImg.png"),
      importIco: require("@/assets/images/pages/importImg.png"),
      PenIco: require("@/assets/images/pages/pencil.svg"),
      priorities: [
        {
          text: this.$t("g.addCtegory/form/priority/veryhigh"),
          value: "5",
        },
        {
          text: this.$t("g.addCtegory/form/priority/high"),
          value: "4",
        },
        {
          text: this.$t("g.addCtegory/form/priority/avrage"),
          value: "3",
        },
        {
          text: this.$t("g.addCtegory/form/priority/Low"),
          value: "2",
        },
        {
          text: this.$t("g.addCtegory/form/priority/veryLow"),
          value: "1",
        },
      ],
      myId: null,
      stages: [
        {
          stage: 1,
          text: this.$t("g.Category/stage/mianData"),
          active: true,
          disabled: false,
        },
        {
          stage: 2,
          text: this.$t("g.Category/stage/specs"),
          active: false,
          disabled: true,
        },
      ],
      currentStage: 1,
    };
  },
  computed: {
    myPid() {
      return this.pid;
    },
  },
  methods: {
    async submit() {
      this.isloading = true;
      this.$refs.addCategoryForm.validate().then(async (sucess) => {
        if (sucess) {
          this.isloading = false;
          this.currentStage = this.currentStage + 1;
        } else {
          this.isloading = false;
        }
      });
    },
    deleteAll() {
      this.form_data.image = null;
    },
    handelActiveSage(data, currentStage) {
      return data.map((ele) => {
        ele.active = ele.stage == currentStage;
      });
    },
    changeStage(newStage) {
      this.currentStage = newStage;
    },
    resetPage() {
      this.currentStage = 1;
      (this.form_data = {
        name: null,
        priority: 0,
        is_visible: true,
        image: null,
      }),
        this.$bvModal.hide("add-modal-category");
      this.$emit("resetData");
    },
    handelImageChange(event) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    },
  },
  watch: {
    currentStage(newVal, oldVal) {
      const newValue = this.stages.map((ele) => {
        if (ele.stage && ele["stage"] === newVal) {
          ele.active = true;
          return { ...ele };
        } else {
          ele.active = false;
          return { ...ele };
        }
      });
      this.stages = newValue;
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
