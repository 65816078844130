<template>
  <div class="add-specs">
    <b-table :fields="fields" class="table" :items="keys">
      <template #head(name)>
        {{ $t("categoryKEy") }}
      </template>
      <template #cell(name)="data">
        <input
          :ref="data.item.id"
          :contenteditable="
            globalPermission['spec-keys'] && globalPermission['spec-keys'].store
          "
          :disabled="
            globalPermission['spec-keys'] &&
            !globalPermission['spec-keys'].store
          "
          class="unset-all"
          v-model="data.item.value"
        />
      </template>
      <!-- <template #cell(is_requierd)="data">
        <b-form-checkbox
          v-model="data.item.is_required"
          name="check-button"
          switch
        />
      </template> -->
    </b-table>
    <button
      v-if="
        globalPermission['spec-keys'] && globalPermission['spec-keys'].store
      "
      class="add-key-btn"
      @click="addNewKey"
    >
      <img :src="plusIcon" alt="plus-icon" />
      {{ $t("g.addCtategory/form/addKey") }}
    </button>
    <b-row class="d-flex justify-content-center m-1">
      <b-button variant="primary" class="col col-8" @click="submitKeys">
        {{ $t("g.addCategory/form/submit") }}
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { BTable, BButton, BFormCheckbox, BRow } from "bootstrap-vue";

export default {
  props: ["id", "form_data", "myPid"],
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BRow,
  },
  data() {
    return {
      keys: [],
      fields: ["name"],
      plusIcon: require("@/assets/images/icons/plus-sm.svg"),
    };
  },
  methods: {
    valueChanged(key, value) {
      const chenged = this.keys.find((e) => e.id == key);
      this.keys = this.keys.map((ele) => {
        if (ele.id == chenged.id) {
          ele.value = this.$refs[key].innerText;
          ele.value = null;
          return ele;
        } else {
          return ele;
        }
      });
    },
    addNewKey() {
      this.keys.push({
        id: +this.keys.length + 1,
        value: "",
        is_required: true,
      });
    },
    async submitKeys() {
      this.$emit("toggle-loading");
      try {
        const formData = new FormData();
        for (const item in this.form_data) {
          if (item == "is_visible") {
            formData.append(item, this.form_data[item] ? 1 : 0);
          } else {
            formData.append(item, this.form_data[item]);
          }
        }
        const parent_id = this.myPid === 0 ? 1 : this.myPid;
        formData.append("parent_id", parent_id);
        formData.append("is_leaf", true);

        this.keys.map((ele) => {
          ele.is_required = ele.is_required ? 1 : 0;
          ele.key = ele.value;
          ele.name = ele.value;
          return ele;
        });
        this.keys.forEach((ele, index) => {
          for (const item in ele) {
            if (item !== "value" && item !== "id") {
              formData.append(`spec_keys[${index}][${item}]`, ele[item]);
            }
          }
        });
        // formData.append("category_id", this.id);
        const response = await this.$http.post("admin/categories", formData);
        if (response.status == 200 || 201) {
          this.$emit("toggle-loading");
          this.$emit("reset-page-data");
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.$emit("toggle-loading");
      }
    },
  },
  // watch: {
  //   form_data(newValue) {
  //     console.log(newValue);
  //   },
  // },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
